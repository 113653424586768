import React, { useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import './LoginModal.css';
import { AuthContext } from './auth/AuthContext';
const LoginModal = ({ show, onClose, onLoginSuccess }) => {
  const { isLoggedIn, login, logout } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault(); // Evita la recarga de la página por defecto al enviar el formulario

    // Preparar la solicitud para el backend
    const loginData = {
      username: username,
      password: password,
    };

    login(loginData);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Login to Spotweb</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className="button-modal" type="submit">Login</button>
        </form>

        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <button className="close-modal" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default LoginModal;