import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from './auth/AuthContext';
import './Spinner.css';
import { Link, useNavigate } from 'react-router-dom';
import Modal from './LoginModal';  // Modal para el login
import './SpotwebTable.css';
import Pagination from './Pagination';
import SearchPanel from './SearchPanel';
import _ from 'lodash';
import { FaDownload, FaSearch, FaUndo } from 'react-icons/fa';
import VisitedPosts from './VisitedPosts'; // Importa tu componente de posts visitados
import Recommendations from './Recommendations';

const SpotwebTable = () => {
  const { isLoggedIn, login, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useState({});
  const [isSearchPanelVisible, setIsSearchPanelVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);    // Estado para mostrar el modal
  const [abortController, setAbortController] = useState(false);
  const perPage = 25;

  const handleTitleClick = (id) => {
    navigate(`/post/${id}`);
  };

  const handlePosterSearch = (poster) => {
    setSearchParams({ ...searchParams, poster });
    setCurrentPage(1);
  };

  const handleSubcataSearch = (subcata, category) => {
    setSearchParams({ ...searchParams, subcata, category });
    setCurrentPage(1);
  };

  // Función para abrir el modal
  const openModal = () => setShowModal(true);

  // Función para cerrar el modal
  const closeModal = () => setShowModal(false);

  // Función para manejar el login exitoso
  const handleLoginSuccess = () => {
    login(); //call login function from context
    closeModal();
  };

  // Función para hacer logout
  const handleLogout = () => {
    logout(); //call logout function from context
  };

  const fetchSpots = _.debounce(async (currentPage, searchParams = {}) => {
    if (abortController) {
      abortController.abort();
    }
    const controller = new AbortController();
    setAbortController(controller);
    let query_cat = ""
    try {
      let query = `?page=${currentPage}&per_page=${perPage}`;
      if (searchParams.title) query += `&title=${searchParams.title}`;
      // Verificar si searchParams.category es un solo valor o un arreglo
      if (searchParams.category!== undefined && searchParams.category !== null) {
        query_cat = `&category=${searchParams.category}`;
      }
      if (searchParams.category && searchParams.category.length > 0) {
        query_cat = `&category=${searchParams.category.join(',')}`;
      }
      query += query_cat
      if (searchParams.poster) query += `&poster=${searchParams.poster}`;
      if (searchParams.genre) query += `&genre=${searchParams.genre}`;
      if (searchParams.subcata) query += `&subcata=${searchParams.subcata}`;

      fetch(process.env.REACT_APP_API_URL + `/latest_posts${query}`, {
        signal: controller.signal,
        credentials: 'include',
      })
        .then(response => response.json())
        .then(data => {
          if (data.spots) {
            setData(data.spots);
          }
          if (data.total) {
            setTotal(data.total);
          }
        });
    } catch (error) {
      console.error('Error fetching spots:', error);
    } finally {
      setLoading(false);
    }
  }, 100);

  useEffect(() => {
    const savedPage = localStorage.getItem('currentPage');
    if (savedPage) {
      setCurrentPage(parseInt(savedPage, 10));
    }

    try {
      setLoading(true);
      fetchSpots(currentPage, searchParams);
    } catch (error) {
      console.error('Error loading data:', error);
    }
    finally{
      setLoading(false)
    }

    const interval = setInterval(() => {
      fetchSpots(currentPage, searchParams);
    }, 120000);

    return () => clearInterval(interval);
  }, [currentPage, searchParams]);

  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
  };

  /*const toggleSearchPanel = () => {
    setIsSearchPanelVisible(!isSearchPanelVisible);
  };*/

  const resetSearch = () => {
  setSearchParams({});
  setCurrentPage(1);
  }

  const getCategoryClass = (category) => {
    switch (category) {
      case 0:
        return 'cat0';
      case 1:
        return 'cat1';
      case 2:
        return 'cat2';
      case 3:
        return 'cat3';
      default:
        return '';
    }
  };

  const handleDownloadNZB = async (messageid, post_id) => {
    try {      
      // Registrar la descarga en el backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register_download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({          
          post_id: post_id,
          messageid: messageid,
        }),
      });

      if (response.ok) {
        console.log('Download registered successfully');
      } else {
        console.error('Failed to register download');
      }
    } catch (error) {
      console.error('Error registering download:', error);
    }

    // Continuar con el proceso de descarga del archivo NZB
    window.location.href = messageid; // Iniciar la descarga
  };

  return (
    <div className="spotweb-container flex">
      {loading ? (
        <div className="loading-animation">
          <div className="spinner"></div>
          <p>Loading ...</p>
        </div>
      ) : (
        <>
          <div className="spotweb-content w-full md:w-3/4">
          <div><button className="login-button" onClick={isLoggedIn ? handleLogout : openModal}>
               {isLoggedIn ? 'Logout' : 'Login'}
              </button></div>
            <div className="header overflow-x-auto">
              <h1 className="text-2xl font-bold mb-4">Spotweb Posts</h1>
            </div>
            <table className={`min-w-full bg-white border border-gray-200 ${!loading ? 'loaded' : ''}`}>
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Category</th>
                  <th className="py-2 px-4 border-b">Title</th>
                  <th className="py-2 px-4 border-b">#</th>
                  <th className="py-2 px-4 border-b">Genre</th>
                  <th className="py-2 px-4 border-b">Sender</th>
                  <th className="py-2 px-4 border-b">Age</th>
                  <th className="py-2 px-4 border-b">Size</th>
                  <th className="py-2 px-4 border-b">NZB Link</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td onClick={() => handleSubcataSearch(item.categories_info, item.category)}
                      style={{ cursor: 'pointer' }}
                      className="py-2 px-4 border-b">
                        <button className={`category-button ${getCategoryClass(item.category)}`}>
                          {item.subcata}
                        </button>

                    </td>
                    <td
                      onClick={() => handleTitleClick(item.id)}
                      style={{ cursor: 'pointer' }}
                      className="py-2 px-4 border-b"
                    >
                      {item.title}
                    </td>
                    <td className="py-2 px-4 border-b">{item.commentcount}</td>
                    <td className="py-2 px-4 border-b">{item.genre}</td>
                    <td className="py-2 px-4 border-b">
                      <a
                        onClick={() => handlePosterSearch(item.poster)}
                        style={{ cursor: 'pointer', color: '#000' }}
                        className="hover:underline"
                      >
                        {item.poster}
                      </a>
                    </td>
                    <td className="py-2 px-4 border-b">{item.stamp}</td>
                    <td className="py-2 px-4 border-b">{item.filesize}</td>
                    <td
                      className="py-2 px-4 border-b"
                      style={{ textAlign: window.innerWidth > 768 ? 'right' : 'left' }}
                    >
                      <a onClick={() => handleDownloadNZB(item.messageid, item.id)}  // Llamar la función de descarga
                        className="nzb-link"
                        download
                        style={{ textAlign: 'left', cursor: 'pointer' }}
                      >
                       NZB <FaDownload />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              total={total}
              perPage={perPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
          {/* Modal de Login */}
          {!isLoggedIn && <Modal show={showModal} onClose={closeModal} onLoginSuccess={handleLoginSuccess} />}
          {/* Sidebar con los últimos posts visitados */}
          <div className="visited-posts-sidebar w-1/4 p-4">
             <SearchPanel onSearch={setSearchParams} onResetSearch={resetSearch}/>
	           <VisitedPosts />
             <Recommendations />
          </div>
        </>
      )}
    </div>
  );
};

export default SpotwebTable;

